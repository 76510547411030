import 'jquery-ui';

class edeclaration {

    incorrect_details = function()
    {
        const opts = {
            changeMonth: true,
            showButtonPanel: true,
            changeYear: true,
            yearRange: '-90:-18',
            defaultDate: '01-01-2000',
            dateFormat: 'dd-mm-yy',
        };

        $('input.datepicker').datepicker(opts);
    }

    step2 = function()
    {
        const $buttons = app.DOM.content.find('a.button');
        const $checkboxes = app.DOM.content.find('input');

        $buttons.on('click', e => {
            const $el = $(e.currentTarget);
            let data = e.currentTarget.dataset;

            // do nothing
            if( $el.hasClass('button-grey') ) {
                return;
            }

            data.msg = data.msg.replaceAll('?', '?\n').replaceAll('.', '.\n');

            if( !confirm(data.msg) ) {
                return;
            }

            // redirect user
            window.location = data.href;
        });

        $checkboxes.on('change', function(){
            if( $checkboxes.filter(':checked').length === $checkboxes.length ){
                $buttons.removeClass('button-grey');

                $buttons.each(function(){

                    const colour = $(this).attr('data-colour');

                    $(this).removeClass('button-grey').addClass(`button-${colour}`)

                });
            } else {
                $buttons.removeClass('button-red button-green').addClass('button-grey');
            }
        });
    }
}

$(function(){

    if( app.OPTIONS.step === 'incorrect_details' ) {
        new edeclaration().incorrect_details();
    } else if( app.OPTIONS.step === 'step2' ) {
        new edeclaration().step2();
    }
});